/**
 * See README for more information on this route
 */
import * as i from 'types';
import { GetStaticPaths, GetStaticProps, InferGetStaticPropsType } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { useRouter } from 'hooks';
import { fetchHeaderLocations } from 'queries/contentful/locations';
import { fetchNavigation } from 'queries/contentful/navigation';
import { fetchGeneralPage, fetchGeneralPages } from 'queries/contentful/pages';
import Page from 'modules/contentful/Page';

const GeneralPage: i.NextPageComponent<GeneralPageProps> = ({
  headerLocations,
  navigation,
  pageData,
  preview,
  showZendesk,
}) => {
  const router = useRouter();

  // Page is not done generating yet
  if (router.isFallback || !pageData) return null;

  return <Page {...{ headerLocations, navigation, pageData, preview, showZendesk }} />;
};

export const getStaticPaths = (async (ctx) => {
  // Skip generating all paths during development
  if (__DEV__) {
    return {
      paths: [],
      fallback: true,
    };
  }

  const pages = await fetchGeneralPages(ctx.defaultLocale!);

  return {
    paths: pages
      // Filter out pages that already have a static route in /pages
      // @TODO think of a more elegant way to do this that scales better
      .filter((page) => page.fields.slug !== 'stories')
      .map((page) => {
        // Only include pages with a page type such as "landing" e.g. "/landing/[slug]"
        if (page.fields.type && page.fields.type !== 'page') {
          return {
            params: {
              slug: [page.fields.type, page.fields.slug],
            },
          };
        }

        // Add this page to root path e.g. "/[slug]""
        return {
          params: {
            slug: [page.fields.slug],
          },
        };
      }),
    fallback: true,
  };
}) satisfies GetStaticPaths;

export const getStaticProps = (async (ctx) => {
  const locale = ctx.locale ?? ctx.defaultLocale!;
  const preview = ctx.preview;

  // Only match the first slug (e.g. /a/b -> a) or default to 'home'
  const slug = ctx.params?.slug ? ctx.params.slug[0] : 'home';

  const [navigation, headerLocations, pageData] = await Promise.all([
    fetchNavigation(locale, preview),
    fetchHeaderLocations(locale, preview),
    fetchGeneralPage(locale, slug, preview),
  ]);

  if (!pageData) {
    return {
      notFound: true,
    };
  }

  // If the page type is 'landing' for example: redirect to /en/landing/[slug]
  if (ctx.params?.slug && ctx.params.slug.length === 1 && pageData.fields.type !== 'page') {
    return {
      redirect: {
        destination: `/${locale}/${pageData.fields.type}/${pageData.fields.slug}`,
        statusCode: 301,
      },
    };
  }

  return {
    props: {
      navigation,
      headerLocations,
      pageData: pageData as i.TypePageGeneral | undefined,
      ...(await serverSideTranslations(locale)),
      showZendesk: Boolean(pageData.fields.showZendesk),
      preview: preview || null,
    },
    revalidate: 30, // 30 seconds
  };
}) satisfies GetStaticProps<any, Queries>;

export type GeneralPageProps = InferGetStaticPropsType<typeof getStaticProps>;

type Queries = {
  slug?: string[];
};

export default GeneralPage;
